<template>
	<div class="app-wrapper" v-loading.fullscreen.lock="loading" element-loading-text="拼命加载中" v-wartermark="SITE_CONFIG">
		<div class="sidebar-container">
			<div class="logo">
			</div>
			<div>
				<div class="menu-icon el-icon-caret-top" v-if="menuIcon" @click.stop="menuScorll(1)"></div>
				<div class="menu">
					<div :class="{'menu-item':1,'menu-item-active':$route.meta.parentId=='home'}"
						@click="$router.replace('/home')">
						<img src="@/assets/img/menu/home.png" alt="首页">
						<span>首页</span>
					</div>
					<div :class="{'menu-item':1,'menu-item-active':$route.meta.parentId==item.menuId}"
						v-for="(item,index) in menuList" :key="index" @click="redirect(item)">
						<img :src="requireUrl(`/menu/${item.icon}.png`)" :alt="item.name">
						<span>{{item.name}}</span>
					</div>
				</div>
				<div class="menu-icon el-icon-caret-bottom" v-if="menuIcon" @click.stop="menuScorll()"></div>
			</div>
			<div class="logo">
			</div>
		</div>
		<div class="main-container" v-if="!loading">
			<div class="menu" v-if="menuItem.list && menuItem.list.length">
				<div :class="{'menu-item':1,'menu-item-active':$route.meta.menuId==item.menuId}"
					v-for="(item,index) in menuItem.list" :key="index" @click="redirects(item)">
					<img :src="requireUrl(`/menu/${item.icon}.png`)" :alt="item.name">
					<span>{{item.name}}</span>
				</div>
			</div>
			<div :class="{time:1,'time-c':!(menuItem.list && menuItem.list.length)}">
				<!-- <XwTime /> -->
				<el-dropdown :show-timeout="0" placement="bottom">
					<div class="el-dropdown-link">
						<div>
							<img src="~@/assets/img/avatar.gif" :alt="nickName" />
							<div>
								{{ nickName }} <i class="el-icon-caret-bottom"></i>
							</div>
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
						<el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="main-content main-content-tabs">
				<!-- 'main-content-tabs':1, -->
				<el-tabs tab-position="top" type="border-card" v-model="activeMenuId" @tab-click="tabClick" closable
					@tab-remove="removeTab" v-if="$route.name!='home'">
					<el-tab-pane :name="item.menuId" v-for="(item,index) in tabsMenuList" :key="index">
						<span slot="label">
							<img :src="require(`@/assets/img/menu/${item.icon}.png`)" style="width:25px;height:25px">
							{{item.name}}
						</span>
					</el-tab-pane>
				</el-tabs>
				<transition name="fade" mode="out-in">
					<keep-alive>
						<router-view />
					</keep-alive>
				</transition>
			</div>
			<div style="text-align: center;margin-bottom: 10px;">
				<a :href="SITE_CONFIG.Website" target="_blank" style="color: #000;">
					技术支持：{{SITE_CONFIG.NAME}}
				</a>
			</div>
			<div class="bottom-time">{{time}}</div>
		</div>
		<!-- 弹窗, 修改密码 -->
		<update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
	</div>
</template>

<script>
	import {
		info
	} from '@/api/sys/user'
	import {
		clearLoginInfo
	} from '@/utils'
	import UpdatePassword from "./update-password";
	export default {
		name: 'Layout',
		components: {
			UpdatePassword,
		},
		data() {
			return {
				SITE_CONFIG: window.SITE_CONFIG,
				loading: true,
				menuIcon: true,
				updatePassowrdVisible: false,
				// menuItem: {},
				homeItem: {
					menuId: 'home',
					icon: 'home',
					name: '首页',
				},
				userInfo: {
					type: 6
				},
				time: ''
			}
		},
		computed: {
			activeMenuId: {
				get() {
					return this.$route.meta.menuId
				},
				set(val) {

				}
			},
			menuList: {
				get() {
					return this.$store.state.common.menuList
				},
				set(val) {

				}
			},
			menuItem: {
				get() {
					return this.$store.state.common.menuItem
				},
				set(val) {
					this.$store.commit('common/updateMenuItem', val)
				}
			},
			tabsMenuList: {
				get() {
					return this.$store.state.common.tabsMenuList
				},
				set(val) {
					this.$store.commit('common/updateTabsMenuList', val)
				}
			},
			nickName: {
				get() {
					return this.$store.state.user.nickName || this.$store.state.user.name;
				},
			},
		},
		mounted() {
			this.getUserInfo()
			this.getTime()
			if (process.env.NODE_ENV != 'development') {
				this.ban();
			}
			window.onresize = () => {

			};
		},
		watch: {
			$route: {
				immediate: true,
				handler(to, from) {
					this.getMenuItem()
				},
				deep: true
			}
		},
		methods: {
			ban() {
				setInterval(() => {
					debugger;
				}, 50);
			},
			tabClick(tab) {
				const tabs = this.tabsMenuList.find(item => item.menuId == tab.name)
				if (tabs) {
					this.redirects(tabs)
				}
			},
			removeTab(tabName) {
				this.tabsMenuList = this.tabsMenuList.filter((item) => item.menuId !== tabName);
				if (this.tabsMenuList.length >= 1 && this.$route.meta.menuId == tabName) {
					this.redirects(this.tabsMenuList[this.tabsMenuList.length - 1])
				} else if(this.tabsMenuList.length < 1){
					this.$router.replace({
						name: "home"
					});
				}
			},
			getTime() {
				this.time = new Date().format('yyyy-MM-dd hh:mm:ss')
				setTimeout(this.getTime, 1000)
			},
			logout() {
				this.$confirm(`确定退出当前登录账号吗?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						clearLoginInfo();
						this.$router.replace({
							name: "login"
						});
					})
					.catch((e) => {
						console.log(e);
					});
			},
			// 修改密码
			updatePasswordHandle() {
				this.updatePassowrdVisible = true;
				this.$nextTick(() => {
					this.$refs.updatePassowrd.init();
				});
			},
			redirect(item = {}) {
				// console.log(item, this.$route, this.$router)
				if (this.$route.meta.parentId == item.menuId) return false
				if (item.list && item.list.length) {
					this.redirects(item.list[0])
				}
			},
			redirects(item = {}) {
				console.log(item)
				if (item.url == 'sentry') {
					const routeUrl = this.$router.resolve({
						path: '/' + item.url
					})
					window.open(routeUrl.href, '_blank')
				} else {
					this.$router.push('/' + item.url)
				}
				// this.setTabsMenuList(item)
			},
			setTabsMenuList(item = {}) {
				item = this.menuItem.list.filter(v => v.menuId == this.$route.meta.menuId)[0];
				var tab = this.tabsMenuList.filter(v => v.menuId == this.$route.meta.menuId)[0];
				if (!tab) {
					this.tabsMenuList = this.tabsMenuList.concat(item);
				}
			},
			menuScorll(state) {
				const menu = document.querySelector('.menu')
				const scrollTop = menu.scrollTop
				let scrollTo = 0
				if (state) {
					if (scrollTop < 70) {
						scrollTo = 0
					} else {
						scrollTo = scrollTop - 70
					}
				} else {
					if (scrollTop > menu.scrollHeight - menu.clientHeight - 70) {
						scrollTo = menu.scrollHeight - menu.clientHeight
					} else {
						scrollTo = scrollTop + 70
					}
				}
				menu.scrollTo({
					top: scrollTo,
					behavior: 'smooth'
				})
			},
			getMenuItem() {
				this.menuItem = this.menuList.filter(item => item.menuId == this.$route.meta.parentId)[0] || {}
				if (this.menuItem.list && this.menuItem.list.length) {
					this.setTabsMenuList(this.menuItem.list)
				}
			},
			// 获取当前管理员信息
			getUserInfo() {
				this.loading = true
				info().then(({
					data
				}) => {
					if (data && data.result) {
						// if (data.data.type == 6) {
						// 	this.redirect(this.menuList[0])
						// } else {
						// 	this.$router.replace('/homes')
						// }
						setTimeout(() => {
							this.loading = false
						}, 1000)
						this.userInfo = data.data
						this.$store.commit('user/updateNickName', data.data.nickName);
						this.$store.commit('user/updateName', data.data.username);
						this.$store.commit('user/updateId', data.data.userId);
						sessionStorage.setItem('user', JSON.stringify(data.data));
					} else {
						this.$message.error('当前用户无权限')
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	.app-wrapper {
		width: 100%;
		min-width: 1080px;
		height: 100%;
		display: flex;
		position: relative;
		background: #dee2ed;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;

		.sidebar-container {
			width: 70px;
			flex-shrink: 0;
			transition: width .5s;
			overflow: hidden;

			.logo {
				width: 70px;
				height: 70px;
				padding: 0;
				margin-bottom: 10px;
				background: transparent;
				padding: 10px;

				img {
					border-radius: 10px;
					width: 100%;
					height: 100%;
				}
			}

			>div {
				padding: 10px 0;
				width: 100%;
				max-height: 80%;
				background: rgba(60, 66, 88, 0.8);
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: center;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				overflow: hidden;
			}

			.menu-icon {
				height: 30px;
				font-size: 26px;
				text-align: center;
				color: #ccc;
				flex-shrink: 0;
				cursor: pointer;
			}

			.menu-icon-setting {
				display: flex;
				align-items: center;
				justify-content: space-around;
				height: 50px;
				padding-top: 10px;
				font-size: 20px;
				width: 80%;
			}

			.menu-item-active,
			.menu-item:active,
			.menu-item:hover {
				color: #fff;
				background-color: rgba(#272d45, .5);
			}

			.menu {
				width: 100%;
				flex: 1;
				overflow: auto;

				.menu-item+.menu-item {
					margin-top: 10px;
				}

				>div {
					height: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					color: #fff;
					cursor: pointer;

					img {
						display: block;
						width: 40px;
					}

					img+* {
						margin-top: 10px;
					}

				}
			}
		}

		.fade-enter-active {
			transition: opacity 1s;
		}

		.fade-enter,
		.fade-leave-to {
			opacity: 0;
		}

		.main-container {
			width: calc(100% - 70px);
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex-shrink: 0;
			margin-top: 10px;
			position: relative;

			.time {
				position: absolute;
				right: 0;
				top: 0px;
				padding-right: 10px;
				padding-top: 10px;
				border-bottom-left-radius: 10px;
				background: #dee2ed;
				z-index: 1;
				transition: padding-top .3s;
				// transform:scale(.6)

				.el-dropdown-link {
					cursor: pointer;
					text-align: center;
					white-space: nowrap;
					height: 35px;

					img {
						width: 35px;
						height: auto;
						margin-right: 5px;
						border-radius: 100%;
						vertical-align: middle;
					}

					>div {
						display: flex;
						align-items: center;
						justify-content: space-between;

						>div {
							display: inline-block;
							width: 88px;
							overflow: hidden;
							text-overflow: ellipsis;
							text-align: center;
						}
					}
				}
			}

			.time-c {
				padding: 10px;
				padding-top: 0px;
			}

			.time-c::before {
				content: '';
				position: absolute;
				top: 0px;
				left: -20px;
				width: 20px;
				height: 20px;
				background: transparent;
				border-radius: 35px;
				box-shadow: 10px 10px 0 #dee2ed;
				transform: rotate(-90deg);
			}

			.time-c::after {
				content: '';
				position: absolute;
				bottom: -20px;
				right: 10px;
				width: 20px;
				height: 20px;
				background: transparent;
				border-radius: 35px;
				box-shadow: 10px 10px 0 #dee2ed;
				transform: rotate(-90deg);
			}

			.menu {
				height: 60px;
				width: calc(100% - 205px);
				display: flex;
				align-items: center;
				overflow: hidden;
				overflow-x: auto;
				transition: all .3s;
				margin-bottom: 10px;

				&::-webkit-scrollbar {
					margin-right: 5px;
					width: 6px;
					height: 6px;
					display: block;
				}

				&::-webkit-scrollbar-thumb {
					background: hsla(0, 0%, 0%, 0.2);
					border-radius: 3px;
					box-shadow: inset 0 0 5px hsla(0, 0%, 0%, 0.05);
					display: block;
				}

				&::-webkit-scrollbar-track {
					background: hsla(0, 0%, 0%, 0.15);
					border-radius: 3px;
					box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.05);
					display: block;
				}

				.menu-item {
					height: 100%;
					min-width: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					flex-shrink: 0;
					color: #000;
					cursor: pointer;
					border-radius: 10px;
					font-size: 12px;

					img {
						display: block;
						width: 30px;
					}

					img+* {
						margin-top: 5px;
					}

				}

				.menu-item+.menu-item {
					margin-left: 10px;
				}

				.menu-item-active,
				.menu-item:hover {

					img+* {
						text-shadow: 5px 0px 15px var(--xw-color-primary);
					}
				}

			}

			.main-content {
				margin: 10px;
				margin-top: 0px;
				flex: 1;
				background: #fff;
				border-radius: 10px;
				overflow-y: auto;
				transition: all .3s;
				position: relative;

				// >* {
				// 	flex: 1;
				// 	padding: 20px;
				// 	overflow-y: auto;
				// 	position: relative;
				// }

				&-tabs {
					>* {
						padding: 0;
					}

					>.el-tabs+* {
						height: calc(100% - 40px);
						padding: 20px;
						overflow-y: auto;
						position: relative;
					}

					>.el-tabs {
						height: 40px;
						box-shadow: none;
						overflow-y: hidden;
						border: none;

						.el-tabs__content {
							display: none !important;
						}
					}
				}
			}
		}

	}

	.xw-content {
		display: flex;
		flex-direction: column;

		.xw-search {
			flex-shrink: 0;
		}

		.xw-table {
			flex: 1;
		}
	}

	.bottom-time {
		position: fixed;
		bottom: 10px;
		right: 15px;
	}
</style>