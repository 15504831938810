<template>
	<el-form-item :label="item.extraName+'：'" class="user-json">
		<el-upload class="avatar-uploader" :action="upload" :show-file-list="false" :on-success="handleSuccess"
			v-if="item.type==3">
			<img v-if="values" :src="values" class="avatar">
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
		</el-upload>
		<el-select v-model="values" clearable filterable allow-create default-first-option placeholder="请选择"
			v-else-if="item.type==0" @change="changeSelect">
			<el-option v-for="(item,index) in getExtraList(item)" :key="index" :label="item" :value="item" v-if="item" />
		</el-select>
		<el-input v-model="values" clearable placeholder="请输入" v-else @input="changeSelect" />
	</el-form-item>
</template>

<script>
	import {
		upload
	} from '@/api/admin';
	export default {
		props: {
			value: {
				type: [Number, String],
				default: ''
			},
			item: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				upload,
				values: '',
			}
		},
		watch: {
			value: {
				immediate: true,
				handler() {
					this.values = this.value
					this.changeSelect()
				},
				deep: true
			},
		},
		methods: {
			changeSelect() {
				this.$emit('input', this.values)
				this.$forceUpdate()
			},
			getExtraList(item) {
				try {
					return JSON.parse(item.extraList)
				} catch (e) {
					return []
				}
			},
			handleSuccess(res, file) {
				this.$emit('input', res.data)
			},
		},
	}
</script>

<style lang="scss">
	.user-json {
		.avatar-uploader {
			width: 100%;
			height: 120px;

			.el-upload {
				width: 100%;
				height: 120px;
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;

				&:hover {
					border-color: #409EFF;
				}
			}

			&-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100%;
				height: 120px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.avatar {
				width: 100%;
				height: 120px;
				display: block;
			}
		}
	}
</style>